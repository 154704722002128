import { render } from "./RichMenuTemplate.vue?vue&type=template&id=c72f9d7e&scoped=true"
import script from "./RichMenuTemplate.vue?vue&type=script&lang=ts"
export * from "./RichMenuTemplate.vue?vue&type=script&lang=ts"

import "./RichMenuTemplate.vue?vue&type=style&index=0&id=c72f9d7e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-c72f9d7e"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QToolbar,QToolbarTitle,QCardSection,QSplitter,QCardActions});
