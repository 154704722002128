import { render } from "./WelcomeMessageForm.vue?vue&type=template&id=014d5d50&scoped=true"
import script from "./WelcomeMessageForm.vue?vue&type=script&lang=ts"
export * from "./WelcomeMessageForm.vue?vue&type=script&lang=ts"

import "./WelcomeMessageForm.vue?vue&type=style&index=0&id=014d5d50&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-014d5d50"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QIcon,QBtn,QItemLabel});
