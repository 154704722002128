
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { Watch } from 'vue-property-decorator'
import { constant } from '@/utils/constants'
import { IInitialCampaign, IScenarioTemplate } from '@/utils/types'
import { ACTION_INITIAL, ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import ScenariosMaker from '@/components/scenarios/ScenariosMaker.vue'
import InitialCampaignMixin from './mixins/InitialCampaignMixin.vue'
import InitQuickReply from './setting/InitQuickReply.vue'
import WelcomeMessageForm from './setting/WelcomeMessageForm.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import { ObjectUtils } from '@/utils/objects'

@Options({
  components: {
    ScenariosMaker,
    InitQuickReply,
    WelcomeMessageForm,
    TestModeSelector,
    CLoading,
  },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class CampaignSettingForm extends mixins(InitialCampaignMixin, BaseFormMixin) {
  isScenarioEditingMode = false
  isTestMode = false
  isCopy = false
  loading = true
  componentKey = 0
  bar = 'setting'
  scenarios: ICard[] = []
  directionType = '0'
  form: IInitialCampaign = {
    _id: '',
    app_id: this.selectedAppId,
    title: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    story_id: '',
    prod_detail: {
      direction_type: 1,
      welcomes: [
        {
          name: '',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: true,
        start_survey: '',
        is_start_survey: false,
        restart_survey: '',
        is_restart_survey: false,
      },
      scenarios: [],
    },
    test_detail: {
      direction_type: 1,
      welcomes: [
        {
          name: '',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: false,
        start_survey: '',
        is_start_survey: true,
        restart_survey: '',
        is_restart_survey: true,
      },
      scenarios: [],
    },
  }

  get tabs() {
    return this.bar
  }

  set tabs(tab: string) {
    this.bar = tab
  }

  get requiredRule() {
    const requiredRule = {
      campaignName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.campaign_name'),
      }),
    }
    return requiredRule
  }

  get saveBtnText() {
    // if (this.bar === 'scenarios') {
    //   return this.$t('reflect')
    // }
    return this.$t('reflect')
  }

  get stateEdit() {
    return Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)
  }

  scenarioEditingMode(editing: boolean) {
    this.isScenarioEditingMode = editing
  }

  async updateTestMode(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.TEST_MODE, item)

    return isSuccess
  }

  onCancel() {
    this.testMode = this.isTestMode
    this.goto('initials', { app_id: this.selectedAppId })
  }

  async onRevert() {
    this.testMode = this.isTestMode
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      this.fetchSelectingIntial()
    }
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        this.loading = true
        await this.doSave()
        this.loading = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false

    if (!this.form._id) {
      this.form.is_active = false
      this.form.app_id = this.selectedAppId
      this.form.test_detail = this.form.prod_detail
      success = await dispatch(ACTION_INITIAL.ADD, {
        ...this.form,
      })
    } else {
      success = await dispatch(ACTION_INITIAL.UPDATE, {
        ...this.form,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.initForm = cloneDeep(this.parentForm)
      this.goto('initials', { app_id: this.selectedAppId })
    }
  }

  onDetailCopy() {
    this.isCopy = !this.isCopy
  }

  @Watch('actionType', { immediate: true })
  async fetchSelectingIntial() {
    if (!this.selectedAppId) {
      return
    }

    if (this.selectedCampaignId !== '') {
      const data: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEM, this.selectedCampaignId)
      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.title = this.form.title + ' Copy'
          this.form.is_test_mode = false
          this.form.is_active = false
          this.form.story_id = ''
          if (this.form.prod_detail) {
            this.form.prod_detail = await ObjectUtils.copyScenario(this.$store, this.form.prod_detail)
          }
          if (this.form.test_detail) {
            this.form.test_detail = await ObjectUtils.copyScenario(this.$store, this.form.test_detail)
          }
        }
      }
    }

    if (this.selectedScenarioTemplateId !== '') {
      const scenario_template: IScenarioTemplate = await this.$store.dispatch(
        ACTION_SCENARIO_TEMPLATE.LOAD_ITEM,
        this.selectedScenarioTemplateId
      )
      if (scenario_template) {
        if (this.form.test_detail) {
          this.form.test_detail.scenarios = scenario_template.scenario
        }
        if (this.form.prod_detail) {
          this.form.prod_detail.scenarios = scenario_template.scenario
        }
      }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)

    this.onChangeTestMode()
  }

  onChangeDirection(value) {
    this.directionType = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.direction_type = Number.parseInt(value)
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.direction_type = Number.parseInt(value)
      }
    }
  }

  onChangeScenarios(value) {
    this.scenarios = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.scenarios = value
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.scenarios = value
      }
    }
  }

  editStart() {
    this.bar = 'setting'
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.scenarios = this.form.test_detail.scenarios ?? []
        this.directionType = this.form.test_detail.direction_type?.toString() ?? '0'
      }
    } else {
      if (this.form.prod_detail) {
        this.scenarios = this.form.prod_detail.scenarios ?? []
        this.directionType = this.form.prod_detail.direction_type?.toString() ?? '0'
      }
    }

    this.componentKey = this.componentKey + 1
  }

  async created() {
    this.loading = true
    this.fetchSelectingIntial()

    if (this.selectedCampaignId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }
    this.isCancel = false
    this.loading = false
  }
}
