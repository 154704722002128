<template>
  <!-- <div class="row">
    <CLoading :loading="loading" />
    <q-space />
    <DateRangeSelectorV2
      v-model="dateRange"
      :disable="false"
      opens="left"
      class="full-width"
      @update:onChangeDateRange="changeDateRange"
    ></DateRangeSelectorV2>
  </div> -->
  <div class="row">
    <div class="col"></div>
    <div class="col-2 bg-white q-pa-sm">
      <div class="text-subtitle2 text-grey-8">期間選択</div>
      <div class="row no-wrap items-center">
        <DateRangeSelectorV2
          opens="left"
          :disable="false"
          v-model="dateRange"
          @update:onChangeDateRange="changeDateRange"
          class="full-width"
        ></DateRangeSelectorV2>
      </div>
      <div class="row q-col-gutter-xs q-mt-xs">
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今日"
            class="full-width"
            @click="changeDate('today')"
            :class="currentDateType === 'today' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="昨日"
            class="full-width"
            @click="changeDate('yesterday')"
            :class="currentDateType === 'yesterday' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今週"
            class="full-width"
            @click="changeDate('thisWeek')"
            :class="currentDateType === 'thisWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前週"
            class="full-width"
            @click="changeDate('lastWeek')"
            :class="currentDateType === 'lastWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今月"
            class="full-width"
            @click="changeDate('thisMonth')"
            :class="currentDateType === 'thisMonth' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前月"
            class="full-width"
            @click="changeDate('lastMonth')"
            :class="currentDateType === 'lastMonth' ? 'btn-save-grey' : ''"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="q-pa-md q-gutter-md text-center" :key="componentKey">
    <EChart
      v-if="friendsChart"
      :titleChart="chart_title"
      :xAxisData="friendsChart.xAxis"
      :seriesData="seriesData"
      :showLegend="true"
      :chartColor="'rgba(92, 252, 169, 1)'"
      :chartBgColor="'rgba(92, 252, 169, 0.4)'"
    ></EChart>
  </div>

  <div class="q-pa-md q-gutter-md">
    <q-table
      flat
      dense
      v-if="campaignsStatistic"
      :rows="campaignsStatistic"
      row-key="date"
      bordered
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header>
        <q-tr>
          <q-th style="width: 150px"></q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.delivered') }} </span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.total_user_of_goal') }}</span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.gtr') }}</span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.total_click') }}</span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.success_rate') }}</span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.success_count') }}</span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="date" :props="props">{{ props.row.date }} </q-td>
          <q-td key="delivered" :props="props"> {{ numberWithCommas(props.row.delivered) }} </q-td>
          <q-td key="total_user_of_goal" :props="props"> {{ numberWithCommas(props.row.total_user_of_goal) }} </q-td>
          <q-td key="gtr" :props="props"> {{ numberWithCommas(props.row.gtr) }}%</q-td>
          <q-td key="total_click" :props="props"> {{ numberWithCommas(props.row.total_click) }}</q-td>
          <q-td key="success_rate" :props="props"> {{ numberWithCommas(props.row.success_rate) }}%</q-td>
          <q-td key="success_count" :props="props"> {{ numberWithCommas(props.row.success_count) }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import EChart from '@/components/app/EChart.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { IDateRangePicker, IOverViewRequest } from '@/utils/types'
import { maska } from 'maska'
import { ChartModel } from '@/models/chart-model'
import { ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { Formater } from '@/utils/formater'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: {
    DateRangeSelectorV2,
    CLoading,
    EChart,
  },
  directives: { maska },
})
export default class CampaignDetails extends mixins(BaseFormMixin) {
  loading = true
  firstLoad = true
  componentKey = 1
  chart_title = ''
  currentDateType = ''

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  friendsChart: ChartModel = new ChartModel([], [])

  campaignsStatistic: IOverViewRequest[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedCampaignType() {
    return this.$route.params.campaign_type || ''
  }

  get selectedCampaignId() {
    return this.$route.params.campaign_id || ''
  }

  async fetchSelectingApp() {
    this.campaignsStatistic = []
    if (!this.selectedCampaignId) {
      return
    }

    this.loading = true

    let action = ''
    if (this.selectedCampaignType === 'initial') {
      action = ACTION_INITIAL.OVERVIEW_DETAIL
    } else {
      action = ACTION_PUSH.OVERVIEW_DETAIL
    }
    console.log(action)
    const resp = await this.$store.dispatch(action, {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
      is_test_mode: false,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
    if (resp) {
      // eslint-disable-next-line
      this.campaignsStatistic = resp[this.selectedCampaignType]
    }
    this.initCharts()
    this.loading = false
  }

  initCharts(): void {
    this.friendsChart = new ChartModel([], [])
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      this.friendsChart.xAxis.push(item.date)
    }

    this.componentKey++
  }

  get seriesData() {
    const delivered: number[] = []
    const total_user_of_goal: number[] = []
    const gtr: number[] = []
    const total_click: number[] = []
    const success_rate: number[] = []
    const success_count: number[] = []
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      delivered.push(item.delivered)
      total_user_of_goal.push(item.total_user_of_goal)
      gtr.push(item.gtr)
      total_click.push(item.total_click)
      success_rate.push(item.success_rate)
      success_count.push(item.success_count)
    }

    return [
      {
        name: this.$t('label.delivered'),
        type: 'line',
        data: delivered,
      },
      {
        name: this.$t('label.total_user_of_goal'),
        type: 'line',
        data: total_user_of_goal,
      },
      {
        name: this.$t('label.gtr'),
        type: 'line',
        data: gtr,
      },
      {
        name: this.$t('label.total_click'),
        type: 'line',
        data: total_click,
      },
      {
        name: this.$t('label.success_rate'),
        type: 'line',
        data: success_rate,
      },
      {
        name: this.$t('label.success_count'),
        type: 'line',
        data: success_count,
      },
    ]
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    this.fetchSelectingApp()
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }
    this.dateRange = value
    this.fetchSelectingApp()
  }

  @Watch('selectedCampaignId', { immediate: true })
  selectedCampaignIdChanged() {
    this.dateRange = DatetimeUtils.getDateRange('last7Days')
    this.fetchSelectingApp()
    this.firstLoad = false
  }

  mounted() {
    if (this.selectedCampaignType === 'initial') {
      this.chart_title = this.$t('label.initial.chart_title')
    } else {
      this.chart_title = this.$t('label.push.chart_title')
    }
  }
}
</script>

<style scoped>
</style>
